/* eslint-disable prettier/prettier */
// @ts-nocheck
export const continentInfo = {
  '3D_Earth_v2003': {
    name: 'na',
    defaultVideo: 'captureland'
  },
  '3D_Earth_v2004': {
    name: 'sa',
    defaultVideo: 'dna'
  },
  '3D_Earth_v2007': {
    name: 'africa',
    defaultVideo: 'superbowl'
  },
  '3D_Earth_v2008': {
    name: 'asia',
    defaultVideo: 'stargazing',
  },
  '3D_Earth_v2009': {
    name: 'europe',
    defaultVideo: 'franca',
  },
  '3D_Earth_v2010': {
    name: 'southpole',
    defaultVideo: 'dont-start-now',
  },
  '3D_Earth_v2011': {
    name: 'oceania',
    defaultVideo: 'libre',
  }
}

export const videoNames = [
  'armani',
  'captureland',
  'columbus',
  'dna',
  'dont-start-now',
  'franca',
  'lebron',
  'libre',
  'milehigh',
  'stargazing',
  'superbowl'
]
